<template>
	<v-app>
		<v-main>
			<v-expand-transition mode="out-in">
				<router-view></router-view>
			</v-expand-transition>
			<r-toast></r-toast>
		</v-main>
	</v-app>
</template>

<script>

export default {
	name: "Index",
}
</script>

<style scoped>

</style>